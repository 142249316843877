import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import VueGtag from "vue-gtag";

import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faLinkedin, faInstagram, faFlickr, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret, faPaperclip, faChevronRight, faPlus, faCheckCircle, faInfoCircle, faTimesCircle, faExclamationTriangle, faExternalLinkSquareAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faTwitter, faLinkedin, faInstagram, faFacebook, faFlickr, faPaperclip, faChevronRight, faPlus, faCheckCircle, faInfoCircle, faTimesCircle, faExclamationTriangle, faExternalLinkSquareAlt, faSignInAlt)
library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import './assets/css/styles.css';

import Faq from './components/Faq.vue';
import Coberturas from './components/Coberturas.vue';
import Conocenos from './components/Conocenos.vue';
import Asistencia from './components/Asistencia.vue';
import Confirmar from './components/Confirmar.vue';
import Aviso_legal from './components/Aviso_legal.vue';
import Cookies from './components/Cookies.vue';
import Transparencia from './components/Transparencia.vue';
import Privacidad from './components/Privacidad.vue';
import Home from './components/Home.vue';
import PrecioSeguroFormulario from './components/PrecioSeguroFormulario.vue';


Vue.config.productionTip = false
const routes = [
  { path: '/faq', component: Faq },
  { path: '/coberturas', component: Coberturas },
  { path: '/conocenos', component: Conocenos },
  { path: '/asistencia', component: Asistencia },
  { path: '/confirmar', component: Confirmar },
  { path: '/contratar', redirect: '/confirmar' },
  { path: '/aviso_legal', component: Aviso_legal },
  { path: '/cookies', component: Cookies },
  { path: '/transparencia', component: Transparencia },
  { path: '/privacidad', component: Privacidad },
  { path: '/', component: Home },
  { path: '/presupuesto_seguro', component: PrecioSeguroFormulario },
]

const router = new VueRouter({
  routes,
  mode: 'history',
});


// G-HLWS15224B
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(VueAxios, axios);
Vue.use( VueSplide );
Vue.use(BootstrapVue,IconsPlugin);
// Vue.use(VueGtag, {  config: { id: "G-HLWS15224B435" }}, router);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
